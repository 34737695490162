<template>
	<body>
		<div class="container p-t-15 p-b-25">
			<h1>Privacy Policy</h1>
			<p>This Data Privacy Policy outlines the principles and practices that Sterling Strategic Innovations Inc., follows to protect the privacy of individuals' personal data. We are committed to ensuring that your personal information is handled responsibly and in compliance with applicable data protection laws.</p>
			<ol>
				<li class="p-t-25">
					<h2>Scope</h2>
					<p>This policy applies to all personal data collected, processed, and stored by Sterling Strategic Innovations Inc., whether obtained through our website, mobile applications, offline interactions, or any other means.</p>

					<p class="p-t-15">Contact information (e.g., name, email address, phone number, Photos)<br />
                        Demographic information<br />
                        Payment and billing information<br />
                        User-generated content<br />
                        Website usage data (e.g., IP addresses, browser information)</p>
				</li>
				<li class="p-t-25">
					<h2>Types of Data We Collect</h2>
					<p>We may collect various types of personal data, including but not limited to:</p>
					<p class="p-t-15">Providing products or services<br/>
						Improving our products and services<br/>
						Communicating with users<br/>
						Marketing and promotional activities<br/>
						Legal and regulatory compliance</p>
				</li>
				<li class="p-t-25">
					<h2>How We Use Our Clients' Contact Information:</h2>
					<p>We collect and process personal data for the following purposes:</p>
					<p class="p-t-15">To communicate inside the application with the activities that is related to your organizations safety and health implementations.<br />
						To respond to your inquiries and provide customer support.<br />
						To comply with legal and regulatory requirements.</p>
				</li>
				<li class="p-t-25">
					<h2>Data Sharing</h2>
					<p>We do not sell, trade, or otherwise transfer your contact information to third parties without your consent, except as required by law or as necessary to fulfill the purposes outlined in this Privacy Statement.</p>
				</li>
				<li class="p-t-25">
					<h2>Data Security</h2>
					<p>We implement appropriate technical and organizational measures to safeguard personal data against unauthorized access, disclosure, alteration, and destruction. This includes the use of encryption, access controls, and regular security assessments.</p>
				</li>
				<li class="p-t-25">
					<h2>Data Retention</h2>
					<p>We retain personal data for as long as necessary to fulfill the purposes for which it was collected, or as required by applicable laws and regulations. When data is no longer needed, it will be securely deleted or anonymized.</p>
				</li>
				<li class="p-t-25">
					<h2>User Rights</h2>
					<p>Individuals have the right to access, rectify, erase, or restrict the processing of their personal data. They also have the right to data portability and to object to the processing of their data in certain circumstances.</p>
				</li>
				<li class="p-t-25">
					<h2>Consent</h2>
					<p>By using our services, users consent to the collection, processing, and storage of their personal data as described in this policy. Users may withdraw their consent at any time by contacting us.</p>
				</li>
				<li class="p-t-25">
					<h2>Updates to the Policy</h2>
					<p>We may update this Data Privacy Policy to reflect changes in our data processing practices or legal obligations. We encourage users to review this policy periodically.</p>
				</li>
			</ol>
		</div>
	</body>
</template>

<script>

export default {
	name: "App",
	components: {},
	methods: {},
	mounted() {}
};
</script>