import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../pages/home/Page.vue'
import PrivacyPolicy from '../pages/privacy_policy/Page.vue'

const routes = [
    { path: '/home', component: Home },
    { path: '/privacy-policy', component: PrivacyPolicy },
    { path: '/', component: Home },
]
const router = createRouter({
    history: createWebHashHistory(),
    routes, 
})

export default router;