import {  createApp } from 'vue'
import App from './App.vue'
import router from './router'
window.$ = window.jQuery = require("jquery");


import './assets/vendor/bootstrap/css/bootstrap.min.css'
import './assets/css/util.css'
import './assets/css/main.css'
createApp(App).use(router).mount('#app')
