<template>
	<body>
		<div class="bg0 bg-img1 size1 flex-w flex-c-m">
			<div class="bg-img1 size1" style="background-image: url(/images/notisafe-ph-teaser.png); position: absolute;z-index: 0;"></div>
		</div>
	</body>
</template>

<script>

export default {
	name: "App",
	components: {},
	methods: {},
	mounted() {}
};
</script>